// import $ from 'jquery/dist/jquery';
// window.$ = window.jQuery = $;

// $.validate = require('./classic/jquery.validate');
// $.validator.unobtrusive = require('./classic/jquery.validate.unobtrusive');

// Using the classic files so there will be minimal changes to the final jquery bundle
import './classic/jquery.min';
import './classic/jquery.validate';
import './classic/jquery.validate.unobtrusive';

console.log('jQuery loaded');